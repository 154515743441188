<template>
  <main class="my-auto container max-w-sm py-10 md:py-20">
    <no-ssr>
      <section>
        <h1 class="text-20 md:text-26 md:font-300 text-center mb-4">
          會員登入
        </h1>
        <div class="font-bold text-16 text-center">
          新會員立即註冊，獲得 200 元購物金！
        </div>
        <hr class="mb-6">
        <div>
          <a
            v-if="isGoogleAvailable"
            class="rounded-10 text-white py-6 px-10 mb-5 block"
            style="background: #db4436"
            :href="GOOGLE_OAUTH_URI"
          >
            <div class="flex items-center">
              <div class="mr-6 lg:mr-12 w-10">
                <img
                  src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-07.png"
                  class="mx-auto block"
                >
              </div>
              <div class="flex-grow text-left text-16">
                使用 Google 帳號登入
              </div>
            </div>
          </a>

          <a
            v-if="isFacebookAvailable"
            class="rounded-10 text-white py-6 px-10 mb-5 block"
            style="background: #4285f3"
            :href="FB_OAUTH_URI"
          >
            <div class="flex items-center">
              <div class="mr-6 lg:mr-12 w-10">
                <img
                  src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-08.png"
                  class="mx-auto block"
                >
              </div>
              <div class="flex-grow text-left text-16">
                使用 Facebook 帳號登入
              </div>
            </div>
          </a>

          <a
            v-if="isLineAvailable"
            class="rounded-10 text-white py-6 px-10 mb-5 block"
            style="background: #00c300"
            :href="LINE_OAUTH_URI"
          >
            <div class="flex items-center">
              <div class="mr-6 lg:mr-12 w-10">
                <img
                  src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-09.png"
                  class="mx-auto block"
                >
              </div>
              <div class="flex-grow text-left text-16">
                使用 LINE 帳號登入
              </div>
            </div>
          </a>
        </div>

        <hr class="my-10">

        <div class="rounded-10 px-5 py-5 border-1 border-eee">
          <div class="mb-4">
            使用 E-mail 登入會員
          </div>

          <input
            v-model="email"
            type="email"
            class="mb-6"
            :disabled="isSentVerifyEmail"
            :class="{ 'opacity-50': isSentVerifyEmail }"
            placeholder="email@example.com"
          >

          <template v-if="isShowVerifyField">
            <div class="mb-4">
              輸入信箱驗證碼
            </div>
            <input
              v-model="verifyCode"
              type="text"
              class="mb-3"
            >
          </template>

          <div class="text-right">
            <button
              v-if="!isSentVerifyEmail"
              class="bg-blue rounded-full text-white inline-block py-3 px-6"
              type="button"
              @click="emailLogin()"
            >
              送出驗證信
            </button>
            <button
              v-if="isSendVerifyEmail"
              type="button"
              class="bg-blue rounded-full text-white inline-block py-3 px-6"
              :disabled="isVerifying"
              @click="verifyDo()"
            >
              確認
            </button>
          </div>
        </div>

        <div class="text-999 mt-5">
          完成登入即表示已閱讀瞭解並同意接受「<nuxt-link
            to="/about/support"
            rel="noopener"
            class="text-blue"
          >
            服務條款
          </nuxt-link>」之所有政策內容，也完全接受現有及未來可能衍生的服務項目及內容。
        </div>
      </section>
    </no-ssr>
  </main>
</template>

<script>
import qs from 'qs';
import Cookies from 'js-cookie';

const isProduction = process.env.NODE_ENV === 'production';

export default {
  head() {
    const title = '登入';
    const canonicalURL = `${process.env.CANONICAL_ORIGIN}/login`;

    return {
      title,
      link: [{ rel: 'canonical', href: canonicalURL }],
      meta: [
        { property: 'og:url', content: canonicalURL },
        { hid: 'og:title', property: 'og:title', content: title },
        { name: 'robots', content: 'noindex' },
      ],
    };
  },

  data() {
    const { redirect = '/', referralCode } = this.$route.query;

    const query = { redirect };

    if (referralCode) {
      Object.assign(query, { referralCode });
    }

    const state = JSON.stringify(query);

    return {
      isGoogleAvailable: false,
      isFacebookAvailable: false,
      isLineAvailable: false,
      isRegister: false,
      isVerifying: false,
      email: '',
      isSentVerifyEmail: false,
      isSendVerifyEmail: false,
      verifyCode: '',
      isShowVerifyField: false,
      GOOGLE_OAUTH_URI:
        'https://accounts.google.com/o/oauth2/v2/auth?' +
        qs.stringify({
          client_id: process.env.GOOGLE_CLIENT_ID,
          redirect_uri: process.env.GOOGLE_OAUTH_REDIRECT_URI,
          state,
          scope: 'openid profile email',
          response_type: 'code',
        }),

      FB_OAUTH_URI:
        'https://www.facebook.com/v5.0/dialog/oauth?' +
        qs.stringify({
          client_id: process.env.FB_CLIENT_ID,
          redirect_uri: process.env.FB_OAUTH_REDIRECT_URI,
          state,
          scope: 'public_profile,email',
          auth_type: 'rerequest',
          response_type: 'code',
        }),

      LINE_OAUTH_URI:
        'https://access.line.me/oauth2/v2.1/authorize?' +
        qs.stringify({
          client_id: process.env.LINE_CLIENT_ID,
          redirect_uri: process.env.LINE_OAUTH_REDIRECT_URI,
          state,
          scope: 'profile openid email',
          response_type: 'code',
        }),

      FB_ACCOUNT_KIT_URI:
        'https://www.accountkit.com/v1.0/basic/dialog/email_login/?' +
        qs.stringify({
          app_id: process.env.FB_CLIENT_ID,
          redirect: process.env.FB_ACCOUNTKIT_REDIRECT_URI,
          state,
          debug: true,
        }),
    };
  },

  async asyncData() {
    let email = '';
    let verifyCode = '';
    if (!isProduction) {
      email = 'bittyferrari@gmail.com';
      verifyCode = '123456';
    }
    return {
      email,
      verifyCode,
    };
  },

  mounted() {
    const self = this;
    setTimeout(() => {
      self.checkBrowser();
    }, 100);
  },

  methods: {
    checkBrowser() {
      // check is from fb browser
      const ua = navigator.userAgent || navigator.vendor || window.opera;
      if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) {
        this.isGoogleAvailable = false;
      } else {
        this.isGoogleAvailable = true;
      }

      this.isFacebookAvailable = true;
      this.isLineAvailable = true;
    },

    reenterEmail() {
      this.isSentVerifyEmail = false;
      this.isShowVerifyField = false;
    },

    verifyDo() {
      this.isVerifying = true;
      this.verifyCode = this.verifyCode.trim();

      const self = this;

      this.post(`auth/email/loginVerify`, {
        _model: 'loginVerify',
        email: this.email,
        verifyCode: this.verifyCode,
      }).then(function(r) {
        const response = r.data;
        switch (response.statusID) {
          case 0: {
            const cookieData = {
              sameSite: 'lax',
              expires: 365,
              secure: isProduction,
            };

            if (isProduction) {
              cookieData.domain = '.unipapa.com';
            }

            Cookies.set('token', response.data.item.token, cookieData);

            self.$toasted.global.success({
              message: '信箱驗證成功',
            });

            self.email = '';
            self.isShowVerifyField = false;

            // push datalayer
            const dataLayerObject = {
              event: 'td_unipapa_login_register',
              User_id: response.item.id,
              user_email: response.item.email,
              new_user: response.isNewUser,
            };
            if (self.isRegister) {
              dataLayerObject.eventCategory = '註冊_成功';
            } else {
              dataLayerObject.eventCategory = '登入_成功';
            }
            window.dataLayer.push(dataLayerObject);

            if (response.isRegister) {
              window.alert(
                '歡迎加入 Unipapa 會員！購物金 200 元已存入帳號，結帳時即可使用。（單筆滿 2000 元折抵 100 元，每筆訂單僅能使用一次，購物金期限三個月內需使用完畢。）'
              );
            }

            try {
              // eslint-disable-next-line
                const urlParams = new URLSearchParams(window.location.search);
              const redirect = urlParams.get('redirect');
              if (redirect == null) {
                self.$router.push('/');
                setTimeout(function() {
                  location.reload();
                }, 1000);
              } else {
                document.location = redirect;
              }
            } catch (e) {
              location.reload();
            }

            break;
          }
          default:
            self.isVerifying = false;
            self.$toasted.global.success({
              message: '信箱驗證失敗',
            });

            // push datalayer
            if (response.item.id) {
              // push datalayer
              const dataLayerObject = {
                event: 'td_unipapa_login_register',
                User_id: response.item.id,
                user_email: response.item.email,
                new_user: response.isNewUser,
              };
              if (self.isRegister) {
                dataLayerObject.eventCategory = '註冊_失敗';
              } else {
                dataLayerObject.eventCategory = '登入_失敗';
              }
              window.dataLayer.push(dataLayerObject);
            }
            break;
        }
      });
    },

    emailLogin() {
      const self = this;
      self.isSentVerifyEmail = true;
      this.post(`auth/email/login`, {
        _model: 'login',
        email: this.email,
      }).then(function(r) {
        const response = r.data;
        self.isSendVerifyEmail = true;
        self.isRegister = response.isRegister;

        switch (response.statusID) {
          case 0:
            self.$toasted.global.success({
              message: '驗證碼已經發送至信箱',
            });
            self.isShowVerifyField = true;
            break;
          default:
            self.$toasted.global.success({
              message: '驗證碼已經發送至信箱',
            });
            break;
        }
      });
    },
  },
};
</script>
